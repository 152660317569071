.section {
  min-height: 100vh;
  min-width: 320px;
  max-width: 1200px;
  width: 100%;
  padding: 0 6rem;

  @media (max-width: 800px) {
    padding: 0 5rem;
  }

  @media (max-width: 600px) {
    padding: 0 3rem;
  }

  @media (max-width: 500px) {
    padding: 0 2rem;
  }
}
