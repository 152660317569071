.card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: var(--foreground);
  color: var(--background);
  border-radius: 0.25rem;
  overflow: hidden;
  padding: 1rem 0.25rem 0.25rem;

  transition: all 300ms ease;

  &:hover {
    transform: scale(1.05);
  }

  margin: 2rem;

  &_name {
    font-size: clamp(1.35rem, 3vw, 2rem);
  }

  .image_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}
