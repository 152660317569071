.projects {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .title {
    font-size: clamp(4rem, 6vw, 5rem);
    font-weight: 500;
    margin-bottom: 7rem;
    letter-spacing: 2px;
  }

  .project_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 5rem;

    .project {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 2rem;

      @media (max-width: 900px) {
        flex-direction: column-reverse;
        gap: 2rem;
      }

      .items {
        display: flex;
        align-items: flex-start;
        gap: 2rem;
        flex-direction: column;

        @media (max-width: 900px) {
          flex-direction: row;
          align-items: flex-end;
        }

        @media (max-width: 600px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      &_role {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        font-size: clamp(1.75rem, 2.5vw, 2.25rem);
      }

      &_name {
        font-size: clamp(3rem, 3vw, 4rem);
        font-weight: 500;
      }

      .row_group {
        width: 100%;
        flex: 1.25;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        @media (max-width: 900px) {
          flex-direction: row;
          align-items: flex-end;
          justify-content: space-around;
        }

        .button_group {
          display: flex;
        }
      }

      .cards {
        width: 100%;
        flex: 4;
      }
    }
  }
}
