$size: 170px; // (Fully responsive)

// Cat
.cat {
  position: relative;
  height: $size;
  width: $size * 1.13;
}

// Ears
.ear {
  position: absolute;
  top: -30%;
  height: 60%;
  width: 25%;
  background: var(--foreground);

  // Ear hair
  &::before,
  &::after {
    content: '';
    position: absolute;
    bottom: 24%;
    height: 10%;
    width: 5%;
    border-radius: 50%;
    background: var(--background);
  }

  &::after {
    transform-origin: 50% 100%;
  }
}

.ear_left {
  left: -7%;
  border-radius: 70% 30% 0% 0% / 100% 100% 0% 0%;
  transform: rotate(-15deg);

  &::before,
  &::after {
    right: 10%;
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.ear_right {
  right: -7%;
  border-radius: 30% 70% 0% 0% / 100% 100% 0% 0%;
  transform: rotate(15deg);

  &::before,
  &::after {
    left: 10%;
  }

  &::after {
    transform: rotate(45deg);
  }
}

// Face
.face {
  position: absolute;
  height: 100%;
  width: 100%;
  background: var(--background);
  border-radius: 50%;
}

// Eyes
.eye {
  position: absolute;
  top: 35%;
  height: 30%;
  width: 31%;
  background: var(--foreground);
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
  overflow: hidden;

  // Eyelids
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 100%;
    border-radius: 0 0 50% 50% / 0 0 40% 40%;
    background: var(--background);
    z-index: 100;
    animation: blink 4s infinite ease-in;
  }

  @keyframes blink {
    0% {
      height: 0;
    }
    90% {
      height: 0;
    }
    92.5% {
      height: 100%;
    }
    95% {
      height: 0;
    }
    97.5% {
      height: 100%;
    }
    100% {
      height: 0;
    }
  }

  // Tips of the eyes
  &::before {
    content: '';
    position: absolute;
    top: 60%;
    height: 10%;
    width: 15%;
    background: var(--foreground);
    border-radius: 50%;
  }
}

.eye_left {
  left: 0;

  &::before {
    right: -5%;
  }
}

.eye_right {
  right: 0;

  &::before {
    left: -5%;
  }
}

.pupil_container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 100;
}

// Pupils
.eye_pupil {
  position: absolute;
  top: 25%;
  height: 50%;
  width: 20%;
  background: var(--background);
  border-radius: 50%;
  border: 1px solid var(--grey-70);
  z-index: 1;

  .eye_left & {
    right: 30%;
  }

  .eye_right & {
    left: 30%;
  }

  // Glare on the pupil
  &::after {
    content: '';
    position: absolute;
    top: 30%;
    right: -5%;
    height: 20%;
    width: 35%;
    border-radius: 50%;
    background: var(--foreground);
    border: 1px solid var(--grey-50);
  }
}

// Muzzle
.muzzle {
  position: absolute;
  top: 60%;
  left: 50%;
  height: 6%;
  width: 10%;
  background: var(--foreground);
  transform: translateX(-50%);
  border-radius: 50% 50% 50% 50% / 30% 30% 70% 70%;
  transition: all 300ms ease-in-out;

  &:hover {
    background: #f888a8;
  }
}
