.about_me {
  display: flex;
  justify-content: center;
  align-items: center;

  .about_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;

    h1 {
      font-size: clamp(3rem, 6vw, 4rem);
      letter-spacing: 2px;
      color: var(--foreground);
    }

    h3 {
      font-size: clamp(2.25rem, 3vw, 1.5rem);
      font-weight: 400;
      letter-spacing: 1.3px;
      color: var(--foreground);
    }

    .about_button {
      display: flex;
    }

    .tools {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 1rem;
      flex-wrap: wrap;

      &_logo {
        height: 5rem;
        width: 5rem;
        transition: all 200ms linear;
        background-color: #fff;
        border-radius: 10px;
        padding: 0.5rem;

        &:hover {
          transform: scale(1.2);
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
    }
  }
}
