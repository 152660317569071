.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;

    padding: 5rem 6rem;

    @media (max-width: 800px) {
      padding: 5rem;
    }

    @media (max-width: 600px) {
      padding: 5rem 3rem;
    }

    @media (max-width: 500px) {
      padding: 5rem 2rem;
    }
  }

  &_span {
    font-size: clamp(1.25rem, 2vw, 2rem);
    font-weight: 300;
  }
}
